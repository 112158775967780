html,
body {
    padding: 0;
    margin: 0;
    padding-top: 0;
    background-color: black;
    color: white;
}

* {
    font-family: Montserrat, Futura, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
